<script>
    import MobileDetect from "mobile-detect";

    export let width = 0;

    export let color = "#FFFFFF";

    let prevWidth = 0;

    export let shouldApplyTransition = false;

    const detector = new MobileDetect(window.navigator.userAgent);

    const device = detector.tablet() !== null ? "tablet" : detector.mobile() !== null ? "mobile" : "desktop";

    $: {
        shouldApplyTransition = Math.abs(prevWidth - width) > 20;

        prevWidth = width;
    }
</script>

<div
    class="progress {device !== 'desktop' ? 'mobile-height' : ''}"
    style="width: {width ? (width > 100 ? 100 : parseFloat(width).toFixed(2)) : 0}%; {color
        ? `background-color: ${color};`
        : ''} {shouldApplyTransition ? 'transition: width 0.2s ease-in;' : ''}"
/>

<style>
    .progress {
        z-index: 10;
        position: fixed;
        top: 0;
        left: 0;
        height: 1px;
        background-color: white;
        border-radius: 4px;
    }
    .mobile-height {
        height: 2px;
    }
</style>
