import { writable } from 'svelte/store';

export const height = writable(window.innerWidth < 769 ? 48 : 68);

window.addEventListener('resize', () => {
    height.set(window.innerWidth < 769 ? 48 : 68)
})

export const locations = writable({
    home: {},
    about: {},
    projects: {},
    contact: {}
})
