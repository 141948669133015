<script>
    import { _ } from "svelte-i18n";

    import Tooltip from "../../../components/tooltip/Tooltip.svelte";
    import IconButton from "../../../components/IconButton.svelte";

    export let show = false;

    export let replay = () => {};

    export let onfileupload = () => {};
</script>

<div class="controls {!show ? 'hide' : ''}">
    <Tooltip title={$_("home.controls.replay")}>
        <IconButton onClick={replay}>
            <span class="material-icons">replay</span>
        </IconButton>
    </Tooltip>
    <Tooltip title={$_("home.controls.upload")}>
        <IconButton>
            <label for="file-input">
                <span class="material-icons">file_upload</span>
            </label>
            <input style="display: none;" on:change={onfileupload} id="file-input" type="file" accept="audio/*" />
        </IconButton>
    </Tooltip>
</div>

<style>
    .controls {
        display: flex;
    }
    
    .controls.hide {
        display: none;
    }

    .controls button span {
        color: lightgray;
        padding: 8px;
    }

    .controls button label {
        cursor: pointer;
    }
</style>
