import { dictionary, locale } from 'svelte-i18n';

import Bulgarian from "./bg.json";
import English from "./en.json";

dictionary.set({
    en: English,
    bg: Bulgarian,
});

const hasLocalStorage = 'localStorage' in window && window['localStorage'] !== null;

const defaultLocale = hasLocalStorage ? window.localStorage.getItem('_lang') : 'en'

locale.set(defaultLocale || 'en');

export const availableLanguages = [
    {
        code: 'en',
        name: 'English',
        short: 'EN'
    },
    {
        code: 'bg',
        name: 'Български',
        short: 'BG'
    }
]

export const changeLanguage = (code) => {
    const index = availableLanguages.findIndex(_l => {
        return _l.code === code
    })

    const newLangCode = index === -1 ? defaultLanguageCode : code;

    if (hasLocalStorage) {
        window.localStorage.setItem('_lang', newLangCode)
    }

    locale.set(newLangCode);
}
