<script>
    import { onMount, onDestroy } from "svelte";
    import { fade } from "svelte/transition";
    import { _ } from "svelte-i18n";
    import MobileDetect from "mobile-detect";

    import Tooltip from "../../../components/tooltip/Tooltip.svelte";
    import IconButton from "../../../components/IconButton.svelte";

    import { clamp } from "../../../utils/mesh";

    const getCursorPos = (e) => {
        e = e || window.event;

        var pageX = e.pageX || e.changedTouches[0].pageX;
        var pageY = e.pageY || e.changedTouches[0].pageY;

        // IE 8
        if (pageX === undefined) {
            pageX = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
            pageY = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
        }

        return { x: pageX, y: pageY };
    };

    const detector = new MobileDetect(window.navigator.userAgent);

    const device = detector.tablet() !== null ? "tablet" : detector.mobile() !== null ? "mobile" : "desktop";

    export let devMode = false;

    export let isPlaying = false;

    export let volume = 1;

    export let show = false;

    export let togglePlay = () => {};

    export let changevolume = () => {};

    export let changevolumecomplete = () => {};

    export let onfileupload = () => {};

    let internalVolume = volume * 100;

    let showVolSlider = false;

    let isChangingVolume = false;

    let isMouseOver = false;

    let lastCursorPos = null;

    const onVolumeChange = (event) => {
        if (
            !showVolSlider ||
            !lastCursorPos ||
            (!event.target.classList.contains("volume-slider-handle") && device !== "desktop") ||
            (!isChangingVolume && device == "desktop")
        ) {
            return;
        }

        const newCursorPos = getCursorPos(event);

        const diff = lastCursorPos.y - newCursorPos.y;

        const newVolume = clamp(internalVolume + diff, 0, 100);

        if (newVolume !== internalVolume) {
            changevolume(newVolume);

            internalVolume = newVolume;
        }

        lastCursorPos = newCursorPos;
    };

    const onVolumeChangeComplete = () => {
        if (!showVolSlider) {
            return;
        }

        changevolumecomplete(internalVolume);

        isChangingVolume = false;

        if (!isMouseOver) {
            showVolSlider = false;
        }
    };

    const onClickVolumeTrack = (event) => {
        if (!showVolSlider) {
            return;
        }

        const rect = document.getElementById("vol-container").getBoundingClientRect();

        const y = clamp(rect.bottom - (event.clientY || event.changedTouches[0].clientY) - 5, 0, 100);

        changevolume(y);

        internalVolume = y;
    };

    const startVolumeChange = (event) => {
        event.preventDefault();
        event.stopPropagation();

        lastCursorPos = getCursorPos(event);

        isChangingVolume = true;

        onClickVolumeTrack(event);
    };

    const getVolumeIcon = (vol) => {
        if (vol === 0) {
            return "volume_off";
        }
        if (vol > 0 && vol <= 33.3) {
            return "volume_mute";
        }
        if (vol > 33.3 && vol <= 66.6) {
            return "volume_down";
        }
        return "volume_up";
    };

    onMount(() => {
        window.addEventListener("mousemove", onVolumeChange, { passive: true });

        window.addEventListener("mouseup", onVolumeChangeComplete, { passive: true });

        window.addEventListener("touchmove", onVolumeChange, { passive: true });

        window.addEventListener("touchup", onVolumeChangeComplete, { passive: true });
    });

    onDestroy(() => {
        window.removeEventListener("mousemove", onVolumeChange);

        window.removeEventListener("mouseup", onVolumeChangeComplete);

        window.removeEventListener("touchmove", onVolumeChange);

        window.removeEventListener("touchup", onVolumeChangeComplete);
    });
</script>

<div class="controls {!show ? 'hide' : ''}">
    <Tooltip title={isPlaying ? $_("home.controls.pause") : $_("home.controls.play")}>
        <IconButton onClick={togglePlay}>
            <span class="material-icons">{isPlaying ? "pause" : "play_arrow"}</span>
        </IconButton>
    </Tooltip>
    {#if devMode}
        <Tooltip title={$_("home.controls.upload")}>
            <IconButton>
                <label for="file-input">
                    <span class="material-icons">file_upload</span>
                </label>
                <input style="display: none;" on:change={onfileupload} id="file-input" type="file" accept="audio/*" />
            </IconButton>
        </Tooltip>
    {/if}
    <div
        class="volume-wrapper"
        on:mouseleave={() => {
            isMouseOver = false;
            if (isChangingVolume) {
                return;
            }
            showVolSlider = false;
            isChangingVolume = false;
        }}
    >
        {#if showVolSlider}
            <div class="volume-container" id="vol-container" in:fade out:fade>
                <div class="volume-slider-track" on:mousedown|nonpassive={startVolumeChange} on:touchstart|nonpassive={startVolumeChange}>
                    <div style="top: {106 - internalVolume}px" class="volume-slider-handle" />
                </div>
            </div>
        {/if}
        <IconButton
            onClick={() => {
                if (device === "desktop") {
                    if (internalVolume === 0) {
                        changevolume(100);

                        internalVolume = 100;
                    } else {
                        changevolume(0);

                        internalVolume = 0;
                    }
                }
            }}
            onMouseEnter={() => {
                showVolSlider = true;
                isMouseOver = true;
            }}
        >
            <span class="material-icons">{getVolumeIcon(internalVolume)}</span>
        </IconButton>
    </div>
</div>

<style>
    .controls {
        display: flex;
    }

    .controls.hide {
        display: none;
    }

    .controls button span {
        color: lightgray;
        padding: 8px;
    }

    .volume-wrapper {
        position: relative;
    }

    .volume-container {
        position: absolute;
        top: -112px;
        left: 15px;
    }

    .volume-slider-track {
        position: relative;
        width: 30px;
        height: 112px;
        overflow: hidden;
    }

    .volume-slider-handle {
        position: absolute;
        top: 6px;
        left: calc(50% - 6px);
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-top: -6px;
        background: #fff;
        cursor: pointer;
    }
    .volume-slider-handle::before {
        content: "";
        position: absolute;
        display: block;
        left: calc(50% - 1.5px);
        width: 3px;
        height: 114px;
        top: -108px;
        background: rgba(255, 255, 255, 0.2);
    }
    .volume-slider-handle::after {
        content: "";
        position: absolute;
        display: block;
        left: calc(50% - 1.5px);
        width: 3px;
        height: 114px;
        top: 0;
        background: #fff;
    }
</style>
