<script>
    import { fade } from "svelte/transition";

    export let label = "Submit";
    export let style = "";
    export let className = "";
    export let onclick = () => {};
    export let loading = false;

    let height = 0;

    const setheight = (event) => {
        height = event.target.getBoundingClientRect().height;
    };
</script>

<button
    on:click={setheight}
    disabled={loading}
    class="{loading ? 'loading ' : ''}{className}"
    style="{loading ? `width: ${height}px; height: ${height}px;` : ''}{style}"
    on:click={onclick}
>
    {#if !loading}
        <p in:fade >{label}</p>
    {/if}
</button>

<style>
    button {
        padding: 10px;
        border-radius: 6px;
        border: 1px solid white;
        color: white;
        text-transform: unset;
        text-decoration: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        background-color: transparent !important;
        transition: width 0.1s ease-in-out, border-radius 0.1s ease-in-out;
    }

    button:before {
        content: "";
        position: absolute;
        opacity: 0;
        z-index: 1;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        padding: 2px;
        border-radius: 6px;
        background: linear-gradient(140deg, #30e098, #61c221, #6b82e5, #a44bd3, #c7288a);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
        transition: width 0.1s ease-in-out, border-radius 0.1s ease-in-out, opacity 0.2s ease-in-out;
    }

    button:hover:before {
        opacity: 1;
    }
    button:disabled {
        cursor: not-allowed;
        border-color: rgba(255, 255, 255, 0.5);
        color: rgba(255, 255, 255, 0.5);
    }

    button:visited {
        color: white;
    }

    .loading {
        border-radius: 50%;
        transition: width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
    }
    .loading:before {
        border-radius: 50%;
        -webkit-animation: spin 1s linear infinite;
        -moz-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
        opacity: 1;
    }
    @-moz-keyframes spin {
        100% {
            -moz-transform: rotate(360deg);
        }
    }
    @-webkit-keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }
    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    button p {
        margin: 0;
    }
</style>
