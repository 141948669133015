<script>
    export let onClick = () => {};

    export let onMouseEnter = () => {};

    export let className = "";

    export let style = "";
</script>

<button class="icon-button {className}" {style} on:click={onClick} on:mouseenter={onMouseEnter}>
    <slot />
</button>

<style>
    .icon-button {
        background-color: unset;
        margin: 4px;
        border-color: unset;
        border-width: 0px;
        cursor: pointer;
    }

    .icon-button:focus {
        -webkit-tap-highlight-color: transparent;
        outline: none;
    }

    .icon-button:active {
        background-color: unset;
        outline: none;
    }
</style>
