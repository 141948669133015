<script>
    import { locale, _ } from "svelte-i18n";
    import { changeLanguage, availableLanguages } from "../locale/index";

    let current_language;

    locale.subscribe((value) => {
        current_language = value;
    });
</script>

<svelte:head>
    <html lang={current_language} />
</svelte:head>

<div class="container">
    {#each availableLanguages as lang}
        <div
            class="langs {current_language === lang.code ? 'current-lang' : ''}"
            on:click={changeLanguage.bind(this, lang.code)}
            on:keypress={changeLanguage.bind(this, lang.code)}
        >
            {lang.short}
        </div>
    {/each}
</div>

<style>
    .container {
        display: flex;
    }
    .langs {
        cursor: pointer;
        margin: 4px;
        color: gray;
        transition: color 0.2s ease-in-out;
    }
    .container .langs:first-child {
        border-right: solid 1px gray;
        padding-right: 4px;
        margin-right: 0px;
    }
    .container .langs:first-child {
        margin-left: 0px;
    }
    .current-lang {
        color: white;
    }
</style>
