<script>
    import { _, json } from "svelte-i18n";

    import Section from "../components/Section.svelte";
    import Card from "../components/Card.svelte";
</script>

<Section id="services-section" isFullHeight={true}>
    <h2>{$_("services.header")}</h2>
    <div class="container">
        <Card icon={"devices"}>
            <h2 slot="header">
                {$_("services.web.header")}
            </h2>
            <div slot="content">
                {#each Object.keys($json("services.web.content")) as key}
                    <p>{$_(`services.web.content.${key}`)}</p>
                {/each}
            </div>
        </Card>
        <Card icon={"api"}>
            <h2 slot="header">
                {$_("services.software.header")}
            </h2>
            <div slot="content">
                {#each Object.keys($json("services.software.content")) as key}
                    <p>{$_(`services.software.content.${key}`)}</p>
                {/each}
            </div>
        </Card>
        <Card icon={"smartphone"}>
            <h2 slot="header">
                {$_("services.mobile.header")}
            </h2>
            <div slot="content">
                {#each Object.keys($json("services.mobile.content")) as key}
                    <p>{$_(`services.mobile.content.${key}`)}</p>
                {/each}
            </div>
        </Card>
    </div>
</Section>

<style>
    .container {
        display: flex;
        justify-content: space-evenly;
    }
    @media screen and (max-width: 1140px) {
        .container {
            flex-direction: column;
        }
    }
</style>
