<script>
    import { useNavigate } from "svelte-navigator";

    export let location = null;

    export let navigate = useNavigate();

    console.log(`landed on ${location ? location.pathname : ""}, redirecting...`);

    navigate("/");
</script>
