
import * as THREE from "three";
import SimplexNoise from "simplex-noise";

export const noise = new SimplexNoise();

export const fractionate = (val, minVal, maxVal) => {
    return (val - minVal) / (maxVal - minVal);
};

export const modulate = (val, minVal, maxVal, outMin, outMax) => {
    var fr = fractionate(val, minVal, maxVal);
    var delta = outMax - outMin;
    return outMin + fr * delta;
};

export const avgArray = (arr) => {
    var total = arr.reduce((sum, b) => {
        return sum + b;
    });
    return total / arr.length;
};

export const maxArray = (arr) => {
    return arr.reduce((a, b) => {
        return Math.max(a, b);
    });
};

export const clamp = (number, min, max) => {
    return Math.min(Math.max(number, min), max);
};

export const distortMesh = (mesh, bassFr, treFr) => {
    bassFr = bassFr < 20 ? 20 : bassFr;

    const pos = mesh.geometry.attributes.position;
    const normal = mesh.geometry.attributes.normal;
    let vertex = new THREE.Vector3();

    for (let i = 0; i < pos.count; i++) {
        vertex.fromBufferAttribute(normal, i);

        const offset = mesh.geometry.parameters.radius;
        const amp = 7;
        const time = window.performance.now();
        const rf = 0.00001;

        const distance =
            offset + bassFr + noise.noise3D(vertex.x + time * rf * 7, vertex.y + time * rf * 8, vertex.z + time * rf * 9) * amp * treFr;

        vertex.multiplyScalar(distance);

        pos.setXYZ(i, vertex.x, vertex.y, vertex.z);
    }

    pos.needsUpdate = true;
};

export const scaleMesh = (mesh, scale) => {
    mesh.scale.x = scale;
    mesh.scale.y = scale;
    mesh.scale.z = scale;
};

export const scaleMeshX = (mesh, scale) => {
    mesh.scale.x = scale * 1.2;
    mesh.scale.y = scale;
    mesh.scale.z = scale;
};