<script>
    import { Router, Route } from "svelte-navigator";

    import Home from "./pages/home/Home.svelte";
    import Services from "./pages/Services.svelte";
    import Projects from "./pages/Projects.svelte";
    import About from "./pages/About.svelte";
    import Contact from "./pages/contact/Contact.svelte";

    import Redirect from "./pages/Redirect.svelte";

    import Navigation from "./components/Navigation.svelte";

    import _package from "../package.json";

    console.log(`app running on: v${_package.version}`);
</script>

<main>
    <Router>
        <Route path="/" primary={false}>
            <Navigation />
            <Home />
            <Services />
            <Projects />
            <About />
            <Contact />
        </Route>
        <Route path="/robots.txt" primary={false} />
        <Route path="/sitemap.xml" primary={false} />
        <Route component={Redirect} primary={false} />
    </Router>
</main>

<style>
    main {
        width: 100%;
        font-family: "Jura", sans-serif;
        overflow-x: hidden;
    }
    :global(body) {
        overflow-x: hidden;
        scroll-behavior: smooth;
    }

    @media screen and (min-width: 769px) {
        :global(body::-webkit-scrollbar) {
            width: 3px;
        }
        :global(body::-webkit-scrollbar:hover) {
            width: 5px;
        }
        :global(body::-webkit-scrollbar-thumb) {
            background: rgba(255, 255, 255, 1);
        }
    }
</style>
