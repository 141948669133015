<script>
    import { _, json } from "svelte-i18n";

    import Section from "../components/Section.svelte";
</script>

<Section id="about-section" isFullHeight={true}>
    <h2>{$_("about.header")}</h2>
    {#each Object.keys($json("about.content")) as key, index}
        <p>{$_(`about.content.${key}`)}</p>
        {#if index < Object.keys($json("about.content")).length - 1}
            <div class="line-break" />
        {/if}
    {/each}
</Section>

<style>
    .line-break {
        width: 40%;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.1);
        margin: 40px auto;
        position: relative;
    }
</style>
