<script>
    import { nanoid } from "nanoid";
    import { fade } from "svelte/transition";

    export let label = "";

    export let value = "";

    export let name = "";

    export let error = null;

    export let style = "";

    export let onchange = () => {};

    const id = nanoid();
</script>

<div class="conatiner {error !== null ? 'error' : ''}" {style}>
    <label for={id}>
        {label}
    </label>
    <textarea {id} {value} {name} on:input={(event) => onchange(event, name)} />
    {#if error !== null}
        <p in:fade out:fade>{error}</p>
    {/if}
</div>

<style>
    .conatiner {
        width: 100%;
        display: flex;
        flex-direction: column;
        color: #fff;
    }
    label {
        width: 100%;
    }
    textarea {
        border: 1px solid #fff;
        border-radius: 6px;
        background-color: transparent;
        margin: 0;
        min-height: 150px;
        color: #fff;
        resize: none;
    }
    p {
        margin: 0;
        font-size: 12px;
    }
    .error {
        color: red;
    }
    .error textarea {
        border-color: red;
        color: red;
    }
</style>
