<script>
    export let href = "";
    export let style = "";
    export let className = "";
    export let alt = "";
</script>

<a class="social-link {className}" style={style} target="_blank" rel="noopener noreferrer" href={href} alt={alt}>
    <slot />
</a>

<style>
    .social-link {
        padding: 6px;
        border-radius: 50%;
        border: 1px solid white;
        color: white;
        text-transform: unset;
        text-decoration: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 4px;
        position: relative;
    }

    .social-link:before {
        content: "";
        position: absolute;
        opacity: 0;
        z-index: 1;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        padding: 2px;
        border-radius: 50%;
        background: linear-gradient(140deg, #30e098, #61c221, #6b82e5, #a44bd3, #c7288a);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
        transition: opacity 0.2s ease-in-out;
    }

    .social-link:hover:before {
        opacity: 1;
    }

    .social-link:visited {
        color: white;
    }
</style>
