<script>
    import { onDestroy, onMount } from "svelte";

    import { height, locations } from "../stores/nav";

    import { getOffsetTop } from "../utils/ui";

    export let isFullHeight = false;

    export let isInnerFullHeight = false;

    export let skipPaddingTop = false;

    export let classNames = [];

    export let styles = "";

    export let innerStyles = "";

    export let id = null;

    let sectionMinHeight = window.innerHeight;

    let navHeight = skipPaddingTop ? 0 : 68;

    height.subscribe((val) => (navHeight = skipPaddingTop ? 0 : val));

    const setOffset = () => {
        const top = getOffsetTop();

        const element = document.getElementById(id);

        const dim = element.getBoundingClientRect();

        locations.update((val) => ({
            ...val,
            [id.split("-")[0]]: { offset: top + dim.top, element, height: dim.height },
        }));

        sectionMinHeight = window.innerHeight;
    };

    onMount(() => {
        if (id !== null) {
            setOffset();

            window.addEventListener("resize", setOffset, false);
        }
    });

    onDestroy(() => {
        if (id !== null) {
            window.removeEventListener("resize", setOffset);
        }
    });
</script>

<div
    id={id ? `${id}` : ""}
    class="section {classNames.join(' ')}"
    style={(isFullHeight ? `min-height: ${sectionMinHeight}px;` : "") + styles}
>
    <div
        style="{isFullHeight && isInnerFullHeight
            ? `min-height: ${sectionMinHeight - (skipPaddingTop ? 0 : navHeight + 20)}px;`
            : ''} padding-top: {skipPaddingTop ? 0 : navHeight + 20}px; {innerStyles}"
    >
        <slot />
    </div>
</div>

<style>
    .section {
        margin-left: auto;
        margin-right: auto;
        max-width: 70%;
    }
    @media screen and (max-width: 768px) {
        .section {
            max-width: 95%;
        }
    }
</style>
