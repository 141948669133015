import { locations } from "../stores/nav";
import { getOffsetTop } from "./ui";

let __recaptchaToken = null;

let __callback = () => {};

let _locations = null;

locations.subscribe((val) => (_locations = val));

export const init = () => {
    const div = document.createElement("div");

    div.setAttribute("id", "g-recaptcha");

    div.style.transition = `opacity 0.2s ease-in-out, visibility 0.2s ease-in-out`;

    document.body.appendChild(div);

    window.setRecaptchaToken = (val) => {
        __recaptchaToken = val;
        __callback(val);
    };

    const onscroll = () => {
        const top = getOffsetTop();

        const offset = _locations && _locations["contact"] ? _locations["contact"].offset - 50 : 1000;

        const shouldShow = top >= offset;

        div.style.visibility = shouldShow ? "visible" : "hidden";
        div.style.opacity = shouldShow ? "1" : "0";
    };

    onscroll();

    window.addEventListener("scroll", onscroll, false);

    grecaptcha.ready(() => {
        grecaptcha.render("g-recaptcha", {
            sitekey: __env["RECAPTCHA_CLIENT_KEY"],
            badge: "bottomleft",
            size: "invisible",
            callback: "setRecaptchaToken",
        });
    });
};

export const execute = async (callback) => {
    if (!grecaptcha) {
        throw new Error("init first");
    }

    if (__recaptchaToken) {
        return callback(__recaptchaToken);
    }

    __callback = callback;

    grecaptcha.execute();
};
