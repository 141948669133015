
export const getOffsetTop = () => {
    return window.pageYOffset || document.documentElement.scrollTop;
};


export const getAspectRatio = () => {
    const ratio = window.innerWidth / (window.innerHeight * 2.15);

    return ratio;
};

export const getWindowDimensions = () => {
    const dims = {
        width: window.innerWidth,
        height: window.innerHeight * 2.15,
        raw: {
            width: window.innerWidth,
            height: window.innerHeight,
        },
    };

    return dims;
};